import IBAN from 'iban';
import { memoize } from 'lodash-es';
import { client } from 'util/apiClient';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/es/lib/isMobilePhone';
import { businessIdValidator } from './business-id';
import { maximumPasswordLength, minimumPasswordLength } from 'components/Security/password-constraints';

type ValidatorRes = string | undefined | Promise<string | undefined>;
type Validator = <T>(value: T) => ValidatorRes;

export const iban = <T>(value: T) => (typeof value === 'string' && IBAN.isValid(value)) || 'Virheellinen tilinumero';

export const compound =
  <T>(...validators: ((value: T) => string | undefined | Promise<string | undefined>)[]) =>
  async (value: T) =>
    validators.reduce<string | undefined | Promise<string | undefined>>(
      async (error, next) => (await error) ?? next(value),
      undefined
    );

export const optional =
  <T>(validator: Validator) =>
  async (value: T) =>
    value === undefined || (typeof value === 'string' && value.length < 1) ? undefined : validator(value);

export const required = <T>(value: T) => (value ? undefined : 'Pakollinen kenttä');

export const phoneNumber = <T>(value: T): string | undefined =>
  typeof value === 'string' && isMobilePhone(value) ? undefined : 'Virheellinen puhelinnumero';

export const email = <T>(value: T): string | undefined =>
  typeof value === 'string' && isEmail(value) ? undefined : 'Virheellinen sähköpostiosoite';

export const businessId = <T>(value: T): string | undefined =>
  typeof value === 'string' && businessIdValidator.isValid(value) ? undefined : 'Virheellinen Y-tunnus';

export const zipcode = <T>(value: T) =>
  typeof value === 'string' && /^\d{5}$/.test(value) ? undefined : 'Virheellinen postinumero';

export const doesCompanyAlreadyExist = (
  otherField: 'businessId' | 'companyName',
  otherFieldValue?: string,
  original = ''
) =>
  memoize(async <T>(value: T): Promise<string | undefined> => {
    const businessId = otherField === 'businessId' ? otherFieldValue : value;
    const companyName = otherField === 'companyName' ? otherFieldValue : value;

    if (typeof value !== 'string' || (companyName && companyName === original)) {
      return undefined;
    }

    try {
      await client.get('/admin/user-management/company-exists', {
        params: { businessId, companyName },
      });
      return undefined;
    } catch (e) {
      if (!e.response || e.response.status !== 422) {
        throw e;
      }
      const { message }: { message: string } = e.response.data;
      return message;
    }
  });

export const emailAvailable = (ignoredEmail?: string) =>
  memoize(async <T>(value: T): Promise<string | undefined> => {
    if (typeof value !== 'string' || (ignoredEmail && value === ignoredEmail)) return undefined;

    try {
      await client.get('/admin/user-management/email-status', { params: { email: value } });
      return undefined;
    } catch (e) {
      if (!e.response || e.response.status !== 422) {
        throw e;
      }
      const { message }: { message: string } = e.response.data;
      return message;
    }
  });

const passwordStatus = memoize(async <T>(value: T): Promise<string | undefined> => {
  if (typeof value !== 'string') return undefined;
  try {
    await client.post('/admin/user-management/password-status', { password: value });
    return undefined;
  } catch (e) {
    if (!e.respose || e.response.status !== 422) {
      throw e;
    }
    const { message }: { message: string } = e.response.data;
    return message;
  }
});

export const minLength =
  (length: number) =>
  <T>(value: T) =>
    typeof value === 'string' && value.length >= length ? undefined : `Vähimmäispituus on ${length} merkkiä`;

export const maxLength =
  (length: number) =>
  <T>(value: T) =>
    typeof value === 'string' && value.length <= length ? undefined : `Enimmäispituus on ${length} merkkiä`;

export const city = compound(minLength(2), maxLength(20));

export const address = compound(minLength(5), maxLength(40), <T>(value: T) =>
  typeof value === 'string' && /[a-zA-ZäöåÄÖÅ]/.test(value) && /\d/.test(value) ? undefined : 'Virheellinen osoite'
);

export const newPassword = compound(minLength(minimumPasswordLength), maxLength(maximumPasswordLength), passwordStatus);

export const matches =
  (getValueToMatch: () => string) =>
  <T>(value: T) =>
    typeof value === 'string' && value === getValueToMatch() ? undefined : 'Kenttä ei täsmää';

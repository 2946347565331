import React from 'react';
import { Box } from '@mezzoforte/forge';
import { Container } from 'components/Container/Container';

const forgeColors = {
  'alert-danger': 'backgroundDanger',
  'alert-warning': 'backgroundWarning',
  'alert-success': 'backgroundSuccess',
  'alert-info': 'backgroundInfo',
} as const;

export interface SystemNotification {
  readonly message: string;
  readonly styleClass: keyof typeof forgeColors;
}

export interface SystemMessageNotificationProps {
  readonly systemNotification?: SystemNotification | null;
}

export const SystemMessageNotification = ({ systemNotification }: SystemMessageNotificationProps) => {
  if (!systemNotification) {
    return null;
  }

  return (
    <Box py={3} backgroundColor={forgeColors[systemNotification.styleClass]} data-test="system-notification">
      <Container dangerouslySetInnerHTML={{ __html: systemNotification.message }} />
    </Box>
  );
};

import React from 'react';
import { Box, Flex } from '@mezzoforte/forge';
import Link from 'components/Link/Link';
import { Container } from 'components/Container/Container';

export interface UnreadMessage {
  readonly id: number;
  readonly title: string;
}

export interface UnreadMessageNotificationProps {
  readonly latestUnreadMessage?: UnreadMessage | null;
}

export const UnreadMessageNotification = ({ latestUnreadMessage }: UnreadMessageNotificationProps) => {
  if (!latestUnreadMessage) {
    return null;
  }

  return (
    <Box py={3} backgroundColor="backgroundInfo" data-test="unread-message-notification">
      <Container>
        <Flex justifyContent="space-between">
          <Link href={`/oma/tiedotteet/${latestUnreadMessage.id}`}>{latestUnreadMessage.title}</Link>
          <Link href={`/oma/tiedotteet/${latestUnreadMessage.id}`}>
            <strong>Lue lisää</strong>
          </Link>
        </Flex>
      </Container>
    </Box>
  );
};

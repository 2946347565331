import React from 'react';
import {
  Alert,
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  TextInput,
} from '@mezzoforte/forge';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { client } from 'util/apiClient';
import { useNotifications } from 'hooks/useNotifications';
import { iban } from 'util/validators';

export interface BankAccountMissingModalProps {
  readonly isOpen: boolean;
  readonly companyId: number;
  readonly onDismiss: () => void;
  readonly onSuccess?: () => void;
  readonly isApprovingBid?: boolean;
}

type Inputs = {
  readonly iban: string;
  readonly confirm: boolean;
};

const addCompanyBankAccount = async (companyId: number, iban: string) =>
  client
    .post<{ readonly success: boolean }>('/api/company-bank-account/add-bank-account', { companyId, bankAccount: iban })
    .then(response => response.data);

export const BankAccountMissingModal = ({
  isOpen,
  companyId,
  onDismiss,
  onSuccess,
  isApprovingBid,
}: BankAccountMissingModalProps) => {
  const { notifications } = useNotifications();
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitSuccessful, isSubmitting, isValid },
  } = useForm<Inputs>({ defaultValues: { iban: '', confirm: false } });

  const onSubmit: SubmitHandler<Inputs> = async data => {
    try {
      await addCompanyBankAccount(companyId, data.iban);
      if (onSuccess) {
        onSuccess();
      }
      return undefined;
    } catch (e) {
      setError('root', { type: 'submitError', message: 'Virhe tallennettaessa tilinumeroa.' });
    }
  };

  const handleDismiss = () => {
    notifications.refetch();
    onDismiss();
  };

  return (
    <Modal isOpen={isOpen ?? false} onDismiss={handleDismiss} variant="secondary">
      <ModalHeader>Syötä yrityksesi tilinumero</ModalHeader>
      {errors.root && (
        <ModalBody>
          <Alert variant="danger" mb={2}>
            {errors.root.message}
          </Alert>
        </ModalBody>
      )}
      {isSubmitSuccessful && (
        <ModalBody>
          <Alert variant="success" mb={2}>
            Tilinumero tallennettu!
          </Alert>
        </ModalBody>
      )}
      {!errors.root && !isSubmitSuccessful && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            {isApprovingBid && (
              <Text as="div" marginBottom={3}>
                Ennen kuin hyväksyt tarjouksen, tarvitsemme vielä yrityksesi tilinumeron. Ilman tilinumeroa emme voi
                siirtää ostajan maksua tilillesi.
              </Text>
            )}
            <Controller
              name="iban"
              control={control}
              rules={{ required: 'Pakollinen kenttä', validate: iban }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  onChange={onChange}
                  onBlur={onBlur}
                  label="Tilinumero IBAN-muodossa"
                  helperText="Esim. FI4250001510000022"
                  isInvalid={!!errors.iban}
                  errorInfo={errors.iban?.message}
                  value={value}
                />
              )}
            />
            <Controller
              name="confirm"
              control={control}
              rules={{ required: 'Pakollinen kenttä' }}
              render={({ field: { onChange, onBlur, value } }) => (
                <CheckboxGroup errorInfo={errors.confirm?.message} isInvalid={!!errors.confirm}>
                  <Checkbox onChange={onChange} onBlur={onBlur} checked={value}>
                    Vakuutan, että tämä on yritykseni tilinumero. Ymmärrän, että kaikki maksut myymistäni kohteista
                    siirretään jatkossa tälle tilille.
                  </Checkbox>
                </CheckboxGroup>
              )}
            />
          </ModalBody>
          <ModalFooter>
            <ButtonGroup spacing={2}>
              <Button onClick={handleDismiss}>Peruuta</Button>
              <Button type="submit" variant="primary" isLoading={isSubmitting} disabled={!isValid}>
                Tallenna
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      )}
    </Modal>
  );
};

'use client';

import dynamic from 'next/dynamic';
import { useSession } from 'hooks/useSession';

const SellerNavigation = dynamic(() =>
  import('components/Header/SellerNavigation').then(module => module.SellerNavigation)
);
const AdminNavigation = dynamic(() =>
  import('components/Header/AdminNavigation').then(module => module.AdminNavigation)
);
const AccountingNavigation = dynamic(() =>
  import('components/Header/AccountingNavigation').then(module => module.AccountingNavigation)
);

export function Precontent() {
  const {
    currentUser: { data },
  } = useSession();

  return (
    <>
      {!data?.user?.isAdmin && data?.user?.isSeller && <SellerNavigation />}
      {!data?.user?.isAdmin && data?.user?.isAccounting && <AccountingNavigation />}
      {data?.user?.isAdmin && <AdminNavigation />}
    </>
  );
}

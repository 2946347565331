import React from 'react';
import dayjs from 'dayjs';
import { GridItem } from '@chakra-ui/react';
import { Text, Link, Box, Heading, Table, Grid, useTokens } from '@mezzoforte/forge';
import { Container } from 'components/Container/Container';
import { formatCurrency } from 'util/currency';
import { formatDate } from 'util/date';
import { priceWithVat } from 'util/price';
import { ShowResponsive } from 'components/Responsive/ShowResponsive';
import { NextLinkButton } from 'components/Link/LinkButton';
import NextLink from 'components/Link/Link';

const { colors } = useTokens.huutokaupat;

export const UnpaidEntriesNotification: React.FC<{ readonly entries?: UnpaidEntry[] | null }> = ({ entries }) => {
  if (!entries || entries.length === 0) {
    return null;
  }

  return (
    <Box py={3} backgroundColor="backgroundSuccess" data-test="unpaid-entries-notification">
      <Container>
        <Heading variant="h4" as="h2">
          Onnea huutokaupan voitosta!
        </Heading>
        <UnpaidEntriesList entries={entries} />
      </Container>
    </Box>
  );
};

export interface UnpaidEntry {
  readonly id: string;
  readonly slug: string;
  readonly isOwnPayment: boolean;
  readonly paymentDue: string;
  readonly title: string;
  readonly vatPerc: number;
  readonly winningBidAmount: number;
}

const UnpaidEntriesList = ({ entries }: { readonly entries: UnpaidEntry[] }) => {
  const yesterday = dayjs().subtract(1, 'day');
  const data = entries.map(entry => ({
    ...entry,
    title: (
      <Link
        as={NextLink}
        href={`/kohde/${entry.id}/${entry.slug}`}
        sx={{
          fontSize: 'sm',
          fontWeight: 500,
          display: 'block',
          lineHeight: '20px',
          fontFamily: 'var(--campton)',
        }}
      >
        {entry.title}
      </Link>
    ),
    bidPrice: formatCurrency(priceWithVat(entry.winningBidAmount, entry.vatPerc)),
    dueDate: (
      <Text
        fontSize="sm"
        sx={
          dayjs(entry.paymentDue).isBefore(yesterday)
            ? {
                fontWeight: 'bold',
                color: 'danger',
              }
            : undefined
        }
      >
        {entry.paymentDue ? formatDate(new Date(entry.paymentDue)) : ''}
      </Text>
    ),
    actions: entry.isOwnPayment ? (
      <Text fontSize="sm">Maksu suoraan myyjälle</Text>
    ) : (
      <NextLinkButton variant="primary-lists" href={`/maksu/${entry.id}`} data-test={`payment-link-${entry.id}`}>
        Maksa
      </NextLinkButton>
    ),
  }));
  return (
    <>
      <Text fontSize="sm" my={2}>
        Maksathan kohteen nopeasti, jotta saat sen pian itsellesi.
      </Text>
      <ShowResponsive mdHide data-test="unpaid-entries-table-mobile">
        {[...data].map(({ id, title, bidPrice, dueDate, actions }) => {
          return (
            <Grid gap="10px" py={3} borderTop={`1px solid ${colors.text}1A`} key={id}>
              <GridItem colSpan={2}>{title}</GridItem>
              <Box>
                <Text fontSize="xs" fontWeight="bold">
                  Tarjouksesi
                </Text>
                <Text fontSize="sm"> {bidPrice}</Text>
              </Box>
              <Box>
                <Text fontSize="xs" fontWeight="bold">
                  Eräpäivä
                </Text>
                {dueDate}
              </Box>
              <GridItem colSpan={2}>{actions}</GridItem>
            </Grid>
          );
        })}
      </ShowResponsive>
      <ShowResponsive md>
        <Table
          data-test="unpaid-entries-table"
          size="compact"
          my={3}
          data={data}
          columns={[
            { Header: 'Kohde', accessor: 'title' },
            { Header: 'Tarjouksesi', accessor: 'bidPrice' },
            { Header: 'Eräpäivä', accessor: 'dueDate' },
            { Header: 'Maksu', accessor: 'actions' },
          ]}
          customCellStyles={{
            // we need !important because <Table> sets flex property inline
            0: { flex: '10 0 auto !important' },
            1: { textAlign: 'right', flex: '1 1 150px !important' },
            2: { textAlign: 'right', flex: '1 1 150px !important' },
            3: { textAlign: 'right', flex: '1 1 200px !important' },
          }}
        />
      </ShowResponsive>
    </>
  );
};

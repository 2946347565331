import React from 'react';
import { useToast, Box, Button, Flex, Heading, Text, TextInput } from '@mezzoforte/forge';
import { client } from 'util/apiClient';
import { useSession } from 'hooks/useSession';
import { Container } from 'components/Container/Container';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import Link from '../../Link/Link';

export interface ConfirmEmailSuccessAPIResponse {
  readonly success: true;
  readonly title: string;
  readonly message: string;
}

export interface PendingEmailChangeInfo {
  readonly email: string;
  readonly isExpired: boolean;
  readonly isBanned: boolean;
}

async function sendConfirmation(form: HTMLFormElement) {
  const response = await client.post<ConfirmEmailSuccessAPIResponse>('/vahvista/vahvistuskoodi', new FormData(form));

  return response.data;
}

const resendConfirmationEmail = async (userId: number) => {
  await client.post(`/api/users/${userId}/send-confirmation-email`);
};

export interface PendingEmailChangeNotificationProps {
  readonly pendingEmailChange?: PendingEmailChangeInfo | null;
}

export const PendingEmailChangeNotification = ({ pendingEmailChange }: PendingEmailChangeNotificationProps) => {
  const confirm = useMutation({
    mutationFn: sendConfirmation,
    onSuccess: ({ title, message }) => {
      playToast(title, message, { variant: 'success' });
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
    onError: () => playToast('Virhe', 'Virhe vahvistettaessa sähköpostia', { variant: 'danger' }),
  });

  const resendEmail = useMutation({
    mutationFn: resendConfirmationEmail,
    onSuccess: () => playToast('Viesti lähetetty', 'Aktivointisähköposti lähetetty uudelleen', { variant: 'success' }),
    onError: () => playToast('Virhe', 'Virhe aktivointisähköpostin lähetyksessä', { variant: 'danger' }),
  });

  const { currentUser } = useSession();
  const { playToast } = useToast();
  const queryClient = useQueryClient();

  const userId = currentUser.data?.user?.id;
  if (!userId || !pendingEmailChange) {
    return null;
  }

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    confirm.mutate(event.currentTarget);
  }

  async function onClickResend(event: React.MouseEvent) {
    event.preventDefault();
    if (!userId) {
      return;
    }

    resendEmail.mutate(userId);
  }

  if (!pendingEmailChange.email) {
    return null;
  }

  const getPendingEmailChangeText = () => {
    if (pendingEmailChange.isExpired) {
      return (
        <Text>
          Sähköpostiosoitteesi vahvistuspyyntö on vanhentunut. Voit vahvistaa osoitteesi lähettämällä viestin uudelleen
          alla olevalla painikkeella ja seuraamalla viestin ohjeita.
        </Text>
      );
    }

    if (pendingEmailChange.isBanned) {
      return (
        <>
          <Text>
            Antamaasi sähköpostiosoitteeseen {pendingEmailChange.email} on ollut vaikeuksia toimittaa viestejä.
            Tehdäksesi tarjouksia, tarvitset toimivan sähköpostiosoitteen, joten pyydämme sinua vahvistamaan antamasi
            sähköpostiosoitteen tai vaihtamaan toiseen osoitteeseen.
          </Text>
          <Text>
            Pääset vaihtamaan sähköpostiosoitteen <Link href="/oma-sivu/yhteystiedot">omilta sivuiltasi</Link>. Voit
            myös lähettää uuden vahvistusviestin nykyiseen sähköpostiosoitteeseesi painamalla alla olevaa nappia.
          </Text>
        </>
      );
    }

    return (
      <Text>
        Olemme lähettäneet antamaasi sähköpostiosoitteeseen {pendingEmailChange.email} viestin, jossa on sekä
        vahvistuslinkki että vahvistuskoodi. Voit vahvistaa sähköpostiosoitteesi joko klikkaamalla viestissä olevaa
        vahvistuslinkkiä tai syöttämällä viestistä löytyvän vahvistuskoodin alla olevaan kenttään.
      </Text>
    );
  };

  return (
    <Box py={3} backgroundColor="backgroundWarning" data-test="email-confirmation-notification">
      <Container>
        <Heading variant="h4" as="h2">
          Sähköpostiosoitteesi pitää vielä vahvistaa
        </Heading>
        {getPendingEmailChangeText()}
        <form onSubmit={onSubmit} data-test="send-confirmation-form">
          <Flex alignItems="flex-end" flexWrap="wrap">
            {!pendingEmailChange.isExpired && (
              <>
                <Box flexBasis={{ base: '100%', md: 'auto' }}>
                  <TextInput name="pincode" isRequired label="Vahvistuskoodi" />
                </Box>
                <Button
                  type="submit"
                  isLoading={confirm.isPending}
                  variant="primary"
                  my={{ base: 1, md: 0 }}
                  ml={{ base: 0, md: 2 }}
                  mr={{ base: 0, md: 5 }}
                  flexBasis={{ base: '100%', md: 'auto' }}
                >
                  Vahvista sähköpostiosoite
                </Button>
              </>
            )}
            <Button flexBasis={{ base: '100%', md: 'auto' }} onClick={onClickResend} isLoading={resendEmail.isPending}>
              Lähetä vahvistusviesti uudelleen
            </Button>
          </Flex>
        </form>
      </Container>
    </Box>
  );
};

import React from 'react';
import { Box, Flex, Heading, List, Text } from '@mezzoforte/forge';
import { capitalize } from 'lodash-es';
import { Container } from 'components/Container/Container';
import { NextLinkButton } from '../../Link/LinkButton';
export type ImportedEntries = Record<string, unknown[]>;

export interface ImportedEntriesNotificationProps {
  readonly importedEntries?: ImportedEntries | null;
}

export const ImportedEntriesNotification = ({ importedEntries }: ImportedEntriesNotificationProps) => {
  if (!importedEntries || Object.keys(importedEntries).length === 0) {
    return null;
  }

  return (
    <Box py={3} backgroundColor="backgroundWarning" data-test="imported-entries-notification">
      <Container>
        <Heading variant="h4" mb={3}>
          Sinulla on muista palveluista tuotuja, keskeneräisiä kohteita
        </Heading>
        <Flex flexDirection={{ base: 'column', md: 'row' }} justifyContent={{ md: 'space-between' }}>
          <Box>
            <Text as="div">Tuotiin:</Text>
            <List>
              {Object.entries(importedEntries).map(([key, entries]) => (
                <Text as="li" key={key}>
                  <strong>{capitalize(key)}:</strong> {entries.length} auton tiedot
                </Text>
              ))}
            </List>
          </Box>
          <NextLinkButton href="/hallinta/keskeneraiset-kohteet">Viimeistele ja julkaise kohteet</NextLinkButton>
        </Flex>
      </Container>
    </Box>
  );
};
